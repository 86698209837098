import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const RetreatRegistrationForm = ({ data }) => {
  return (
    <Layout>
      <SEO title="Retreat Registration" />
      <div id="main">
        <div style={{ backgroundColor: "#FFF" }}>
          <a href="/">
            <img
              id="logo"
              src={data.file.publicURL}
              alt="Enosh Bible Camp Logo"
            />
          </a>
        </div>
        <div className="diagonal">
          <svg viewBox="0 0 100 10" preserveAspectRatio="none">
            <polygon points="100,0 0,10 0,0" style={{ fill: "#FFF" }} />
          </svg>
          <Link className="button--anchor" to="/">
            Return To Home
          </Link>
        </div>
        <div className="info">
          <p>
            <a
              href="/files/enosh_retreat_2024.pdf"
              target="_blank"
              rel="noopener"
            >
              Click here to view full Enosh Bible Camp retreat details.
            </a>
          </p>
          <iframe
            title="Enosh Bible Camp Retreat Registration Form"
            src="https://docs.google.com/forms/d/e/1FAIpQLScmQ5IfgNO3A9d5RgkJN62lbVyDn3R-C-COmM8fXn7f6CnHBw/viewform?embedded=true"
            width="100%"
            height="3600"
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
            onLoad={() => window.parent.scrollTo(0, 0)}
          >
            Loading...
          </iframe>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "campenoshlogomini_web.svg" }) {
      publicURL
    }
  }
`

export default RetreatRegistrationForm
